import * as React from "react"
import Header from "../../../Header/Header"
import ProjectBlock from "../../../UI/ProjectBlock/ProjectBlock"
import ProjectFooter from "../../../UI/ProjectFooter/ProjectFooter"
import ProjectGoals from "../../../UI/ProjectGoals/ProjectGoals"
import ProjectIntro from "../../../UI/ProjectIntro/ProjectIntro"

import "./Viasat.sass"

const challenges = [
  'Interactive animations with unique design',
  'Full-featured platform to launch multiple websties',
  'Support for several languages even in interactive elements',
  'Integration with Viasat programming api',
  'Automated content & promotions update',
]

const solutions = [
  'Admin panel to fill the content for multiple languages;',
  'Unique design and smooth animations for each show;',
  'Mobile device support and cross browser compatibility;',
  'Short-term project delivery.'
]


class Viasat extends React.Component {

  render() {

    const info = [
      { label: "Client", value: "Viasat"},
      { label: "Partnership with", value: "Aimbulance"},
      { label: "Category", value: "Website"},
      { label: "Year", value: "2016"},
    ];

    return (
      <main className='viasat'>
        <Header/>
        <ProjectIntro title='Viasat <br />AAA-Shows'
                      description='The most anticipated shows on Viasat got their own mini-websites with eye-catching design and interactive animations to give the viewer an even more immersive experience and reveal interesting facts and details about the plot or the show itself.'
                      heading='heading-case'
                      info={info}>
          <img src='../../../../images/projects/viasat/intro.png' alt='Illustration: robot and shark'/>
        </ProjectIntro>
        <ProjectGoals challenges={challenges} solutions={solutions}/>
        <ProjectBlock className='viasat__robot-wars'
                      heading='heading-num1'
                      title='Robot Wars'
                      subtitle="Robot Wars is a dynamic show where designers build robots that fight to death in a bulletproof arena. Presenting the show and each skilled fighting robot we also gave user the ability to interact with them and learn each robot characteristics."
                      link='http://explore.aimbulance.com/AAA-robot-wars.html'
                      linkTitle='Robot Wars Show'
                      isImageFirst={true}>
          <img
            src='../../../../images/projects/viasat/robot-wars.png'
            srcSet='../../../../images/projects/viasat/robot-wars@2x.png 2x'
            alt='Illustration: robots with schemes on laptop screen'
          />
        </ProjectBlock>
        <ProjectBlock className='viasat__giants'
                      heading='heading-num2'
                      title='Nature Giants'
                      subtitle="The award-winning wildlife series that gave insights into the evolution of the world's largest animals. The mini site for the show was designed in an interactive way to allow visitors to explore these magnificent creatures in details."
                      link='https://explore.aimbulance.com/AAA_inside_nature_giants.html'
                      linkTitle='Nature Giants Show'
                      image={{
                        src: "../../../../images/projects/viasat/nature-giants.png",
                        alt: "Image: screen Viasat Nature with wheel, shark and polar bear",
                      }}/>
        <ProjectBlock className='viasat__vikings'
                      heading='heading-num3'
                      title='Vikings'
                      subtitle='Vikings is a five-part series that revealed stunning discoveries of the greatest Scandinavian warriors. The website was built using 5 custom built animations that reflected the essence and main idea of each episode.'
                      link='https://explore.aimbulance.com/AAA_viking.html'
                      linkTitle='Vikings Show'
                      centered={true}
                      overlappedHeading
        >
          <div className='max-width'>
            <video width="100%" height="100%" loop autoPlay muted playsInline preload='auto'>
                <source src="../../../../images/projects/viasat/viasat-vikings.mp4" type="video/mp4"/>
            </video>
          </div>
        </ProjectBlock>
        <ProjectFooter title="Vichy<span class='-desktop'>:</span> <br class='-mobile'/>Dermablend"
                       className='viasat__footer'
                       link='/vichy'
                       imageSrc='../../../../images/projects/vichy-footer.png'
                       imageAlt='Image: tube with fluid corrective'
        />
      </main>
    )
  }
}

export default Viasat
