import React, { Component } from "react"
import PageWrapper from "../components/PageWrapper/PageWrapper"
import SEO from "../components/seo"
import Viasat from "../components/Pages/Projects/Viasat/Viasat"

class PlanetaPage extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='Viasat'
          description='The most anticipated shows on Viasat got their own mini-websites with eye-catching design and interactive animations to give the viewer an even more immersive experience and reveal interesting facts and details about the plot or the show itself.'
        />
        <Viasat/>
      </PageWrapper>
    )
  }
}

export default PlanetaPage
